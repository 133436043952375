import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './button';

const PressReleasePreviewStyled = styled.article`
    width: 50%;
    max-width: 710px;
    margin: 48px 40px 60px 0;

    @media screen and (max-width: 1024px) {
        width: 90%;
        margin-right: 0;
    }

    header {
        font-size: 38px;
        font-weight: 700;
        font-style: italic;
        letter-spacing: 2px;
    }

    .tagline {
        margin-top: 20px;
        font-style: italic;
    }

    span {
        display: block;
        margin: 24px 0;
        font: 400 18px roboto, sans-serif;
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: 0.95px;
        color: #4a4f53;
    }

    .subtitle {
        text-decoration: underline;
    }

    .preview-copy {
        height: 192px;
        margin-bottom: 32px;
        color: #6b727a;
        font-size: 16px;
        font-family: roboto, sans-serif;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const PressReleasePreview = ({
    bodyCopy,
    date,
    link,
    location,
    tagline,
    title,
}) => {
    return (
        <PressReleasePreviewStyled>
            <header>{title}</header>
            {tagline ? <p className="tagline">{tagline}</p> : null}
            <span>
                {date} &bull; {location}
            </span>
            <p
                className="preview-copy"
                dangerouslySetInnerHTML={{ __html: bodyCopy }}
            />
            <Button
                buttonText="Read More"
                buttonType="primary"
                link={`/press-and-news/${link}`}
            />
        </PressReleasePreviewStyled>
    );
};

PressReleasePreview.propTypes = {
    bodyCopy: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default PressReleasePreview;
