import React from 'react';
import { withSize } from 'react-sizeme';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PressStyled from '../components/page-styles/press-styles';

import Hero from '../components/hero';
import InfoCard from '../components/infoCard';
import PressReleasePreview from '../components/pressReleasePreview';

import heroImageDesktop from '../images/hero-press-desktop.png';

import pressReleaseData from '../data/press-releases.json';

const pressReleasePreviews = pressReleaseData.map(preview => {
    return (
        <PressReleasePreview
            bodyCopy={preview.body}
            date={preview.longDate}
            link={preview.slug}
            location={preview.location}
            tagline={preview.tagline}
            title={preview.title}
            key={preview.id}
        />
    );
});

const PressPage = ({ path, size }) => (
    <Layout path={path}>
        <PressStyled>
            <SEO title="Press" />
            <Hero
                headerText="Press"
                background={
                    size.width > 768 ? heroImageDesktop : heroImageDesktop
                }
                showLogo={false}
                isSubPage={true}
            ></Hero>

            <section className="previews">{pressReleasePreviews}</section>
            <section>
                <InfoCard
                    className="info-card"
                    title="Press Kit"
                    link="/Pilot-Company-Press-Kit.zip"
                    linkIsExternal
                    buttonText="Download Now"
                />
                <InfoCard
                    className="info-card"
                    title="Media Inquiries"
                    copy="For media inquires, please contact <a href='mailto:media.relations@pilotcompany.com'>media.relations@pilotcompany.com</a>"
                />
            </section>
        </PressStyled>
    </Layout>
);

export default withSize()(PressPage);
